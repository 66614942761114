import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink as HashLink } from 'react-router-hash-link';
import { APPLICATION_BASE_PATH } from '../../constants';
import Spinner from '../Spinner';
import styles from './Button.module.scss';
import { classnames } from '../../utils';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'text' | 'text-inherit' | 'destructive';

type ButtonProps = ComponentProps<'button'> & {
  children: React.ReactNode | string;
  variant?: ButtonVariant;
  as?: 'button' | 'a' | 'span';
  size?: 'standard' | 'large';
  icon?: React.ReactNode;
  isLoading?: boolean;
  fullWidth?: boolean;
  to?: string;
  iconPlacement?: 'left' | 'right';
  hash?: string;
};

export default function Button({
  variant = 'primary',
  size = 'standard',
  icon,
  isLoading,
  fullWidth,
  children,
  iconPlacement,
  as = 'button',
  to,
  hash,
  className = '',
  ...delegated
}: ButtonProps) {
  const Tag = as === 'a' ? LinkWrapper : as;
  const appliedClasses = classnames([
    styles.wrapper,
    styles[variant],
    styles[size],
    fullWidth ? styles.fullWidth : styles.inline,
    fullWidth && !icon ? styles.centerText : styles.inline,
    isLoading && styles.disableClick,
    className
  ]);

  return (
    <Tag className={appliedClasses} to={to} hash={hash} {...delegated}>
      {isLoading ? (
        <span className={styles.loading}>
          <Spinner variant={variant === 'primary' ? 'secondary' : 'primary'} size="small" />
        </span>
      ) : null}
      {icon && (!iconPlacement || iconPlacement === 'left') ? <span className={styles.icon}>{icon}</span> : null}
      {children}
      {icon && iconPlacement === 'right' ? <span className={styles.icon}>{icon}</span> : null}
    </Tag>
  );
}

const LinkWrapper = ({ to, hash, children, ...rest }) => {
  return to ? (
    hash ? (
      <HashLink smooth to={`/${APPLICATION_BASE_PATH}${to}#${hash}`} {...rest}>
        {children}
      </HashLink>
    ) : (
      <Link to={`/${APPLICATION_BASE_PATH}${to}`} {...rest}>
        {children}
      </Link>
    )
  ) : null;
};
