export const sv = {
  global: {
    title: 'Accumbo Webapp',
    loading: 'Hämtar...',
    buttons: {
      save: 'Spara',
      update: 'Uppdatera',
      switch_language: 'Byt språk',
      done: 'Klar',
      cancel: 'Avbryt',
      ok: 'OK',
      next: 'Nästa',
      delete: 'Ta bort',
      sign: 'Signera',
      retry: 'Försök igen'
    },
    undo: 'Ångra',
    sort: 'Sortera efter',
    name: 'Namn',
    years: 'år',
    labresult: 'Provsvar',
    referral: 'Remiss',
    certificate: 'Intyg',
    instruction: 'Instruktion',
    document: 'Dokument',
    diagnosis: 'Diagnos',
    show: 'Visa',
    attach_file: 'Bifoga fil',
    select_file: 'Välj fil',
    no_file_selected: 'Ingen fil vald',
    error_loading_data: 'Kunde inte hämta data.',
    error_loading_file: 'Kunde inte hämta filen.',
    error_uploading_file: 'Kunde inte ladda upp filen.',
    error_saving_note: 'Kunde inte spara anteckningen. Vänligen försök igen.',
    personalNumber: 'Personnummer',
    email: 'E-postadress',
    epicrisis: 'Slutanteckning',
    day_note: 'Daganteckning',
    note: 'Anteckning',
    diagnosis_code: 'Diagnoskod (ICD)',
    male: 'Man',
    female: 'Kvinna',
    days: 'dagar',
    days_ago: 'dagar sedan',
    today: 'I dag',
    yesterday: 'I går',
    other: 'Övrigt',
    file: 'Fil',
    week: 'Vecka',
    weeks: 'veckor',
    two_weeks: '2 veckor',
    month: 'Månad',
    ninety_days: '90 dagar',
    day: 'Dag',
    by: 'av',
    to: 'till',
    date: 'Datum',
    systolic: 'systoliskt',
    diastolic: 'diastoliskt',
    heartrate: 'puls',
    reason: 'Orsak',
    show_error_info: 'Visa teknisk information',
    no_data: 'Information saknas',
    yes: 'Ja',
    no: 'Nej',
    source: 'Källa',
    claim_number: 'Försäkringsskadenr.',
    freetext: 'Fritext',
    change_date: 'Ändra datum',
    feature_disabled: 'Funktionen stöds inte av patientens app.',
    new: 'Ny',
    status: 'Status',
    clear: 'Rensa',
    non_pal_warning:
      'Varning! Du försöker öppna journalen för en patient som du inte är PAL för. Åtkomsten kommer att loggas. Tryck OK för att forcera.',
    non_pas_warning:
      'Varning! Du försöker öppna journalen för en patient som du inte är PAS för. Åtkomsten kommer att loggas. Tryck OK för att forcera.',
    back: 'Tillbaka',
    unknown_date: 'Okänt',
    complete_data: 'Komplettera',
    form: 'Form',
    strength: 'Styrka',
    hits: 'träffar',
    print: 'Skriv ut',
    preview: 'Förhandsgranskning',
    documents: 'Dokument',
    images: 'Bilder',
    phone: 'Telefon',
    phone_number: 'Mobilnummer',
    address: 'Adress',
    create: 'Skapa',
    document_is_signed: 'Dokumentet är underskrivet',
    profile_image: 'Profilbild',
    contact_details: 'Kontaktuppgifter',
    minimize: 'Minimera',
    show_all: 'Visa alla',
    minimize_all: 'Minimera alla',
    hide_all: 'Dölj alla',
    months: {
      1: 'Januari',
      2: 'Februari',
      3: 'Mars',
      4: 'April',
      5: 'Maj',
      6: 'Juni',
      7: 'Juli',
      8: 'Augusti',
      9: 'September',
      10: 'Oktober',
      11: 'November',
      12: 'December'
    },
    previous: 'Föregående',
    next: 'Nästa',
    remaining_characters: 'Återstående tecken: %{remaining}',
    unknown_caregiver: 'Okänd läkare',
    caregiver_role: {
      doctor: 'Leg. läkare',
      nurse: 'Leg. sjuksköterska'
    },
    checkup_type: {
      checkup: 'Kontroll',
      new_member_checkup: 'Första läkarkontrollen',
      three_month_checkup: '3-månaderskontroll',
      yearly_checkup: 'Årskontroll',
      extra_checkup: 'Extra kontroll',
      six_week_checkup: '6-veckorskontroll',
      initial_checkup: 'Initiering av hemblodtrycksmätning',
      nurse_treatment: 'Sjukvårdande behandling, hypertoni',
      nurse_new_member: 'Första sjuksköterskekontrollen',
      nurse_two_week: '2-veckorskontroll',
      nurse_six_month: 'Inför halvårskontroll',
      nurse_yearly: 'Inför årskontroll',
      nurse_extra: 'Extrakontroll',
      reminder: 'Påminnelse',
      nurse_reminder: 'Påminnelse',
      diagnosis_screening: 'Screening',
      examination_follow_up: 'Uppföljning fysisk undersökning',
      kiosk_assessment: 'Kioskbedömning'
    },
    no_final_note_reason: 'Slutanteckningsorsak saknas',
    event_type: {
      newMessage: 'Nytt meddelande',
      profileUpdate: 'Profiluppdatering',
      labResult: 'Provsvar',
      referralFailed: 'Remiss misslyckades',
      lowBloodpressure: 'Lågt blodtryck',
      highBloodpressure: 'Högt blodtryck',
      symptom: 'Symptom',
      checkup: 'Kontroll',
      standing: 'Stående mätning',
      titrationComplete: 'Titrering slutförd',
      titrationIncomplete: 'Titrering ej slutförd',
      memberMissing: 'Medlem inaktiv',
      reminder: 'Påminnelse',
      threeMonthCheckup: 'Kontroll (3 mån.)',
      yearlyCheckup: 'Kontroll (år)',
      followupMissing: 'Uppföljning saknas',
      newMedication: 'Ny medicin',
      noRegion: 'Inget provtagningsalternativ passar',
      missingLabResult: 'Prover ej tagna ännu',
      measurementStart: 'Initiering av hemblodtrycksmätning',
      patientNotReady: 'Pat. ej redo för läkare',
      patientFeeNotPaid: 'Patientavgift ej betald',
      nurseReminder: 'Påminnelse SSK',
      lifestyleReminder: 'Påminnelse - Livsstilsförändring',
      nurseCheckup: 'Kontroll SSK',
      slowOnboarding: 'Patient ej klar att få mätare',
      onboardingComplete: 'Pat. klar med onboarding',
      screeningResult: 'Remissvar',
      alarmValue: 'Larmvärde',
      label: {
        slowOnboarding: 'Fastnat under onboarding',
        followupMissing: 'Saknar bokning',
        titrationIncomplete: 'Nästan klar med onboarding',
        titrationComplete: 'Pat. klar med onboarding',
        newMessage: 'Nytt meddelande',
        lowBloodpressure: 'Larmvärde (lågt)',
        highBloodpressure: 'Larmvärde (högt)',
        patientNotReady: 'Inte redo för läkare',
        standing: 'Stående mätning',
        symptom: 'Symptom',
        reminder: 'Påminnelse',
        nurseReminder: 'Påminnelse',
        lifestyleReminder: 'Påminnelse - Livsstilsförändring',
        patientFeeNotPaid: 'Patientavgift ej betald',
        threeMonthCheckup: 'Pågående konsultation',
        newMemberCheckup: 'Pågående konsultation',
        sixWeekCheckup: 'Pågående konsultation',
        yearlyCheckup: 'Pågående konsultation',
        extraCheckup: 'Pågående konsultation',
        checkup: 'Pågående konsultation',
        nurseCheckup: 'Pågående konsultation',
        onboardingComplete: 'Pat. klar med onboarding',
        alarmValue: 'Larmvärde'
      }
    },
    back_to_inbox: 'Tillbaka till mina ärenden',
    count_suffix: 'st',
    anonymous_mode_notice:
      'Du använder Clinic i anonymiserat läge. Tryck <kbd>Ctrl</kbd>+<kbd>Y</kbd> för att avaktivera.',
    generic_error: 'Något gick fel. Information om felet har vidarebefordrats till utvecklingsteamet.',
    nurse: 'SSK',
    added: 'Tillagd',
    undiagnosed: 'Odiagnostiserad patient',
    referred: 'Fysisk undersökning beställd',
    monitor: {
      ihealth: 'iHealth',
      truly: 'Truly',
      otherManual: 'Manuell inmatning/egen mätare'
    },
    ecg: 'EKG',
    please_note: 'OBS!',
    updated: 'Uppdaterad',
    blood_pressure: 'Blodtryck',
    unknown: 'Okänt',
    certificate_patient: 'Intygspatient',
    language: {
      swedish: 'Svenska',
      english: 'Engelska'
    }
  },
  about_clinic: {
    header: 'Om Clinic',
    accumbo_medical_system: 'Clinic är en del av Accumbo Medical System.',
    address: 'Varvsholmen, Bredbandet 1, 392 30 Kalmar'
  },
  instance_disabled: {
    header: 'Flik inaktiverad',
    body: 'Clinic har öppnats i en annan flik eller webbläsare. Denna flik kan inte längre användas.'
  },
  service_status: {
    active: 'Aktiv',
    hidden: 'Gömd',
    blocked: 'Blockerad',
    doctor: 'Hanteras av läkare',
    nurse: 'Hanteras av SSK',
    deactivating: 'Avaktiveras'
  },
  payment_state: {
    created: 'Betalning begärd',
    pending: 'Betalning pågår',
    exemption: 'Frikort',
    complete: 'Betald',
    failed: 'Betalning misslyckades',
    error: 'Betalning misslyckades',
    cancelled: 'Betalning avbruten'
  },
  notification: {
    generic: {
      error: 'Något gick fel. Försök igen eller kontakta support.'
    },
    assign_responsible_doctor: {
      success: 'Tilldelad som ansvarig läkare',
      error: 'Det gick inte att tilldela ansvarig läkare. Vänligen försök igen.'
    },
    assign_responsible_nurse: {
      success: 'Tilldelad som ansvarig sjuksköterska',
      error: 'Det gick inte att tilldela ansvarig sjuksköterska. Vänligen försök igen.'
    },
    medication_signed: {
      success: 'Medicin signerad',
      error: 'Medicinen kunde inte signeras'
    },
    medication_added: {
      success: 'Medicin tillagd',
      error: 'Medicinen kunde inte läggas till'
    },
    medication_paused: {
      success: 'Medicin pausad',
      error: 'Medicinen kunde inte pausas'
    },
    medication_updated: {
      success: 'Medicin uppdaterad',
      error: 'Medicinen kunde inte uppdateras'
    },
    medication_canceled: {
      success: 'Medicin utsatt',
      error: 'Medicinen kunde inte sättas ut'
    },
    medication_invalidated: {
      success: 'Medicin borttagen',
      error: 'Medicinen kunde inte tas bort'
    },
    medication_dosageRegimen_change: {
      error: 'Kunde inte uppdatera dosering.'
    },
    medication_reason_change: {
      error: 'Kunde inte uppdatera orsak.'
    },
    upload_file: {
      error: 'Det gick inte att ladda upp filen.'
    },
    post_file_to_chat: {
      error: 'Kunde inte skicka filen till chatten.'
    },
    document_created: {
      success: 'Dokument skapat',
      error: 'Det gick inte att skapa dokumentet. Vänligen försök igen.'
    },
    final_note_created: {
      success: 'Slutanteckning skriven.',
      error: 'Det gick inte att skapa någon slutanteckning.'
    },
    create_jira_issue: {
      success: 'Supportärende skapat',
      error: 'Kunde inte skapa supportärende.'
    },
    create_referral: {
      success: 'Remiss skickad.',
      error: 'Kunde inte skicka remissen. Vänligen försök igen eller kontakta support.'
    },
    prepare_referral: {
      success: 'Remiss sparad.',
      error: 'Kunde inte spara remissen. Vänligen försök igen eller kontakta support.'
    },
    delete_checkup: {
      error: 'Kunde inte ta bort bokningen. Vänligen försök igen eller kontakta support.'
    },
    delete_checkup_conflict: {
      error: 'Kunde inte ta bort kontrollen. Patientavgiften är redan betald.'
    },
    save_checkup: {
      error: 'Kontrollen kunde inte uppdateras. Försök igen eller kontakta teknisk support.'
    },
    checkup_saved: {
      success: 'Kontroll bokad %{date}.',
      error: 'Kontrollen kunde inte bokas. Försök igen eller kontakta teknisk support.'
    },
    reminder_saved: {
      success: 'Påminnelse sparad till %{date}.',
      error: 'Påminnelsen kunde inte sparas. Försök igen eller kontakta teknisk support.'
    },
    bypass_actions: {
      success: 'Patient redo för läkarkontroll.',
      error: 'Det gick inte att klarmarkera patienten.'
    },
    upload_profile_picture: {
      success: 'Din profilbild sparades.',
      error: 'Det gick inte att spara profilbilden.'
    },
    snooze_errand: {
      error: 'Det gick inte att snooza ärendet. Försök igen eller kontakta teknisk support.'
    },
    fetch_patient_comment: {
      error: 'Det gick inte att hämta anteckningar om patienten.'
    },
    save_patient_comment: {
      error: 'Det gick inte att spara anteckningen. Försök igen eller kontakta teknisk support.'
    },
    post_physical_examination_referral: {
      success: 'Remiss för fysisk undersökning skapad och skickad i chatten.',
      error: 'Det gick inte att skapa remiss för fysisk undersökning. Försök igen eller kontakta teknisk support.'
    },
    physical_examination_referral_created: {
      success: 'Remiss för fysisk undersökning skickad',
      error: 'Det gick inte att skapa remiss för fysisk undersökning. Vänligen försök igen.'
    },
    post_auscultation_assessment: {
      error: 'Kunde inte spara bedömning av testinspelning. Försök igen eller kontakta teknisk support.'
    },
    convert_draft_to_message: {
      error: 'Kunde inte skicka meddelandet direkt.'
    }
  },
  patient_view: {
    header: 'Patient',
    unsaved_changes_prompt: 'Det finns osparade ändringar. Vill du ändå lämna sidan?',
    error_loading_patient:
      'Kunde inte hämta patienten. Prova igen senare eller kontakta teknisk support. Om du kom hit genom att klicka på en länk, kontrollera att länken är korrekt.',
    tabs: {
      overview: 'Översikt',
      health_profile: 'Anamnes',
      referrals: 'Remisser och svar',
      medicines: 'Läkemedel',
      notes: 'Anteckningar och filer',
      decision_support: 'Beslutsstöd',
      timeline: 'Tidslinje'
    },
    anamnesis: {
      no_answer: 'Inget svar.',
      skipped_question: 'Frågan hoppades över.',
      empty_category: 'Inga frågor i denna kategori.',
      see_medicine_list: 'Se fliken Läkemedel',
      personal_goal_header: 'Målsättning ifylld av patient',
      no_personal_goal: 'Ingen personlig målsättning ifylld.',
      risk_profile_header: 'Riskprofil',
      member_goal_header: 'Målsättning',
      risk_profile_history_link: 'Historik',
      risk_profile_history_header: 'Historik över riskprofil',
      error: 'Kunde inte hämta anamnessvaren.'
    },
    chat: {
      chat_message_placeholder: 'Skriv ett meddelande...',
      chat_search_placeholder: 'Sök chattmeddelande',
      send: 'Skicka',
      create_note: 'Skapa anteckning',
      create_day_note: 'Skapa daganteckning',
      create_epicrisis: 'Skapa slutanteckning',
      polling_error: 'Kunde inte uppdatera chatten. Kontrollera internetanslutningen.',
      generic_post_error: 'Kunde inte skicka meddelandet.',
      conflict_post_error: 'Kunde inte skicka. Det finns redan ett aktivt nudge-meddelande.',
      chat_modal_header: 'Meddelande till {0}',
      automatic_banner_message: 'Utomkontorstidsupplysning',
      fullsize_image_header: 'Bild från patient',
      image_thumbnail_error: 'Kunde inte ladda bild. Klicka för fullversion.',
      refer_to_support: 'Supporthänvisning',
      nudge: 'Aktivera och påminn',
      nudge_tooltip:
        '<p>Detta meddelande triggar påminnelser via mail och SMS.</p><p>Fortsatt inaktivitet genererar supportärende.</p>',
      active_nudge:
        'Patienten får för tillfället påminnelser via mail och SMS. Ett supportärende skapas automatiskt om patienten inte läser ditt meddelande. Du behöver inte skapa ett extra supportmeddelande.',
      draft: {
        delete: 'Ta bort',
        edit: 'Redigera',
        sending_in: 'Skickar om %{time}',
        sending: 'Skickar...',
        stopped: 'Stoppad',
        sending_blocked_active_draft: 'Kan inte skicka ett nytt meddelande när det finns ett aktivt utkast.',
        edit_header: 'Redigerar utkast',
        send_edit: 'Skicka på nytt',
        countdown_tooltip:
          'Du kan lämna denna sida om du inte vill göra fler ändringar, meddelandet skickas automatiskt ändå.',
        countdown_stopped: 'Nedräkningen är stoppad. Du måste skicka meddelandet på nytt för att det ska nå patienten.',
        confirm_deletion: 'Säker på att du vill ta bort ditt utkast?',
        send_now: 'Skicka nu',
        confirm_send_now: 'Är du säker på att du vill skicka meddelandet direkt?'
      },
      filters: {
        pal_message: 'PAL-meddelanden',
        pas_message: 'PAS-meddelanden',
        system_message: 'Systemmeddelanden',
        patient: 'Patientmeddelanden',
        file: 'Filer',
        image: 'Bilder'
      },
      chat_filter_button: 'Chattfiltrering',
      chat_filtered_notice: 'OBS! Chatten filtreras',
      canned_response: {
        support:
          'Hej!\n\nVid ickemedicinska frågor är vår support bättre lämpad att hjälpa dig, så jag ber dig ta kontakt med dem antingen via telefon eller e-post.\n\nRing oss på 0480-797 960 mellan 09.00-12.00 eller 13.00-15.00 på vardagar, alternativt skicka e-post till support@accumbo.se.\n\nDu kan även se vanliga frågor och svar på https://accumbo.se/faq/',
        nudge:
          'Hej %{name}!\n\nJag hoppas att allt är bra med dig. Jag ser att du inte har varit så aktiv i din behandling hos oss på sistone. Återkoppla gärna så att vi kan gå vidare.\n\nVänliga hälsningar,'
      },
      payment_message_header: {
        created: 'Patientavgiftsmeddelande',
        pending: 'Patientavgiftsmeddelande',
        exemption: 'Patientavgift betald',
        complete: 'Patientavgift betald',
        failed: 'Patientavgift obetald',
        error: 'Patientavgift obetald',
        cancelled: 'Patientavgift avbruten'
      },
      payment_message: {
        created: 'Patienten har blivit ombedd att betala patientavgiften (%{date})',
        pending: 'Betalning av patientavgiften pågår (%{date})',
        exemption: 'Patientavgift betald via frikort %{date}',
        exemption_ea: 'EuroAccident står för behandlingen',
        complete: 'Patientavgift betald %{date}',
        failed: 'Betalning av patientavgift misslyckades (%{date})',
        error: 'Betalning av patientavgift misslyckades, tekniskt fel (%{date})',
        cancelled: 'Betalningen avbröts. Kan ske om en kontroll avbokas exempelvis.'
      },
      payment_history_icon_title: 'Gå till betalningshistorik',
      consent_message: {
        available_options: 'Tillgängliga betalsätt',
        consented: 'Patienten har valt betalsätt',
        option_billing: 'Faktura',
        option_exemption: 'Frikort',
        option_not_chosen: 'Patienten har blivit ombedd att välja betalsätt (frikort eller faktura)',
        option_not_chosen_short: 'Patienten har blivit ombedd att välja betalsätt',
        billing: 'Patienten har valt betalsätt (faktura)',
        exemption: 'Patienten har valt betalsätt (frikort)'
      },
      error: {
        title: 'Chatten kunde inte visas.',
        body: 'Information om felet har vidarebefordrats till utvecklingsteamet.'
      }
    },
    graph: {
      relaxation_time: 'Avslappningstid',
      systolic_pressure: 'Systoliskt tryck',
      diastolic_pressure: 'Diastoliskt tryck',
      heartrate: 'Puls',
      no_measurements: 'Inga mätvärden för denna medlem.',
      measurements_header: 'Blodtrycksmätningar',
      measurement_mode: {
        relaxation: 'Vår avslappning',
        symptoms: 'Symptommätning',
        alreadyRelaxed: 'Slappnat av på egen hand',
        standing: 'Stående mätning',
        test: 'Testmätning'
      },
      measurement_times: {
        morning: 'Morgon (05-11)',
        day: 'Dag (11-17)',
        evening: 'Kväll (17-00)',
        night: 'Natt (00-05)'
      }
    },
    averages: {
      average_values_header: 'Medelvärden',
      highest_systolic: 'Högsta SYS',
      lowest_systolic: 'Lägsta SYS',
      highest_diastolic: 'Högsta DIA',
      lowest_diastolic: 'Lägsta DIA',
      average_morning: 'Medel morgon',
      average_day: 'Medel dag',
      average_evening: 'Medel kväll',
      average_night: 'Medel natt',
      number_of_measurements: 'Antal mätningar',
      period_header: 'Medelvärden vecka'
    },
    medications: {
      medication_list: 'Medicinlista',
      accumbo_medications: 'Mediciner utfärdade av Accumbo',
      member_medications: 'Av patient angivna mediciner',
      natural_remedies: 'Naturläkemedel',
      dietary_supplements: 'Kosttillskott',
      when_needed: 'Vid behov',
      no_medications: 'Inga mediciner utskrivna',
      no_member_medications: 'Inga läkemedel angivna',
      no_medication_history: 'Ingen läkemedelshistorik för denna patient',
      no_natural_remedies: 'Patienten tar inga naturläkemedel',
      no_dietary_supplements: 'Patienten tar inga kosttillskott',
      prescribe_medication: 'Förskriv medicin',
      edit_medication_error: 'Kunde inte spara uppdateringarna. Vänligen försök igen.',
      open_prescription_service: 'Öppna Alfa e-Recept',
      prescription_service_description: 'Alla våra mediciner skrivs ut via Alfa e-Recept i ett separat system.',
      prescription_confirm_close: 'Är du säker på att du vill stänga Alfa e-recept utan att spara?',
      get_prescription_session_error: 'Något gick fel när Alfa e-recept skulle laddas. Vänligen försök igen.',
      post_prescription_error: 'Något gick fel när receptet skulle sparas. Vänligen försök igen.',
      add_medication: 'Komplettera medicinlista',
      sign_medications: 'Signera läkemedelslista',
      accumbo_medication_history: 'Historik läkemedel utfärdade av Accumbo',
      member_medication_history: 'Historik läkemedel angivna av patient',
      add_medication_error: 'Kunde inte lägga till medicinen. Vänligen försök igen.',
      sign_medication_error: 'Kunde inte signera läkemedelslistan. Vänligen försök igen.',
      get_member_medications_error: 'Kunde inte hämta medicininformation.',
      signed: 'Signerad',
      medication_signed: 'Medicin signerad',
      paused: 'Pausad',
      renew_n_medications: 'Förnya %{numSelected} recept',
      renew_medications: 'Förnya recept',
      medication_updated: 'Medicin uppdaterad',
      medication_added_notification: 'Medicin tillagd ({0})',
      medication_prescribed_notification: 'Medicin förskriven ({0})',
      medications_prescribed_notification: '{0} mediciner förskrivna',
      medication_reason_saved: 'Medicinanledning sparad',
      medication_paused: 'Medicin pausad',
      medication_canceled: 'Medicin utsatt',
      sign_without_vara_import: 'Fortsätt utan VARA-import',
      section: {
        unsigned_medications: 'Osignerade mediciner',
        current_medications: 'Aktuell medicinlista',
        previous_medications: 'Tidigare mediciner',
        supplements: 'Natur- och kosttillskott',
        anamnesis: 'Anamnesfrågor om läkemedel',
        invalidated_medications: 'Borttagna utan att ha signerats'
      },
      method: {
        scanned: 'Skannad',
        manual: 'Manuell'
      },
      medication_history: {
        header: 'Historik över medicinjusteringar för patient',
        dose_change: 'Dosförändring',
        new_medication: 'Ny medicin',
        cancellation: 'Utsatt medicin',
        new_dose: 'Ny dosering',
        previous_dose: 'Tidigare dosering',
        empty: 'Ingen medicinhistorik'
      },
      header: {
        sign_manual_medication: 'Signera manuellt inmatad medicin',
        sign_scanned_medication: 'Signera inskannad medicin',
        sign_scanned_medication_with_conflict: 'Signera medicin (flera träffar hittades)',
        patient_scanned_info: 'Av patient inskannad information',
        patient_manual_info: 'Av patient angiven information',
        medication_suggestions:
          'Patienten scannade medicinförpackningens streckkod. Vi hittade flera tänkbara alternativ i VARA-registret. Välj det som passar bäst.',
        add_medication_manually: 'Komplettera medicinlista',
        add_medication_manually_sub_header: 'Lägg till medicin manuellt'
      },
      medication_modal: {
        header: 'Uppdatera medicin',
        medication_name: 'Namn på medicin',
        medication_type: 'Läkemedelsform',
        medication_strength: 'Styrka',
        medication_dose: 'Dosering',
        medication_reason: 'Orsak',
        medication_when_needed: 'Endast vid behov',
        medication_npl_pack_id: 'NPLpack-ID',
        medication_atc_code: 'ATC-kod',
        delete_medication: 'Ta bort medicin',
        delete_confirmation: 'Är du säker på att du vill sätta ut medicinen?',
        invalidate_confirmation:
          'Är du säker på att du vill ta bort medicinen utan att signera den? Du kan se medicinen senare längst ner på denna sida.',
        header_add: 'Lägg till medicin',
        added_at: 'Tillagt',
        signed_at: 'Signerad',
        correct_text: 'Korrigera text',
        change_dose: 'Ändra dosering',
        change_reason: 'Ändra orsak',
        update_reason: 'Uppdatera orsak',
        pause_medication: 'Pausa medicin',
        cancel_medication: 'Sätt ut medicin',
        resume_medication: 'Återinsätt medicin',
        paused_until: 'Pausad till',
        paused_indefinitely: 'Pausad på obestämd tid',
        update_dosage: 'Uppdatera dosering',
        dose_update_reason: 'Orsak till dosförändring',
        reason_textarea_placeholder: 'Fritext',
        cancel_medication_reason: 'Orsak till utsättning av medicin',
        add_medication_reason: 'Orsak till förskrivning av medicin',
        recommended_medication_option: 'Rekommenderat förslag',
        recommended_option_reason: 'Denna medicin var den senast tillagda i VARA-registret.',
        sign_with_alternative_option_confirmation: 'Är du säker på att du vill välja den här medicinen?',
        no_medication_options_found: 'Vi kunde inte hitta några medicinalternativ.',
        no_search_results:
          'Inga sökresultat hittades på kombinationen av namn och styrka. Vänligen ändra någon av sökparametrarna.',
        nplpackid_atc_code_missing: 'NPLpack-ID eller ATC-kod saknas',
        dosage_regimen_or_when_needed_missing: 'Dosering eller "Endast vid behov" måste anges.',
        enter_name_to_start_search: 'Ange ett läkemedelsnamn för att börja söka.',
        enter_vara_search: 'Komplettera mot VARA',
        vara_disabled_reason: 'Fyll i namn och läkemedelsform',
        original_version_by_patient: 'Originalversion skapad av patient',
        original_version: 'Originalversion',
        name_notice:
          'Vänligen kontrollera namn och stavning då läkemedelsnamnet inte importerats. Detta visas även i patientens medicinlista.'
      },
      change_dose_modal: {
        current_dose: 'Nuvarande dosering',
        new_dose: 'Ny dosering'
      },
      change_reason_modal: {
        current_reason: 'Nuvarande orsak',
        new_reason: 'Ny orsak'
      },
      pause_medication_modal: {
        pause_indefinitely: 'Pausa medicin på obestämd tid',
        pause_until_date: 'Pausa till datum',
        register_pause_between_dates: 'Registrera uppehåll mellan två datum'
      },
      sign_medication_modal: {
        header: 'Signera medicin',
        sign_button: 'Signera medicin',
        replace_and_sign: 'Ersätt och signera'
      },
      add_medication_modal: {
        header: 'Lägg till medicin',
        add_button: 'Lägg till medicin'
      },
      table_header: {
        name: 'Läkemedelsnamn',
        dose: 'Dosering',
        strength: 'Styrka',
        type: 'Läkemedelsform',
        interval: 'Dosering',
        date_signature: 'Datum och signatur',
        issued_by: 'Utfärdat av',
        canceled_by: 'Utsatt av',
        added: 'Tillagd',
        method: 'Metod',
        prescribed_by: 'Förskrivning',
        invalidated_by: 'Borttagen av'
      },
      empty: {
        unsigned_medications: 'Inga osignerade mediciner.',
        current_medications_maybe_empty: 'Patienten har ännu inte besvarat frågan om aktuell medicinering.',
        current_medications_definitely_empty: 'Patienten har angivit att den inte tar några mediciner.',
        current_medications_empty_for_now:
          'Patienten har angivit att den tar mediciner men har inte lagt till några än.',
        current_medications_empty_but_has_unsigned: 'Patienten har osignerade mediciner.',
        current_medications_empty: 'Patienten tar inte några mediciner för tillfället.',
        previous_medications: 'Inga tidigare mediciner.',
        invalidated_medications: 'Inga mediciner borttagna innan signering'
      },
      errors: {
        loading_medications: 'Kunde inte hämta medicindata.',
        loading_search_results: 'Kunde inte hämta sökresultat från VARA-registret.',
        loading_medication_supplements: 'Kunde inte hämta naturläkemedel och kosttillskott.',
        loading_medication_extras: 'Kunde inte hämta anamnesuppgifter om läkemedel.'
      },
      tabs: {
        medication_information: 'Läkemedelsinformation',
        revisions: 'Revisioner (%{revisionCount})',
        original_copy: 'Originalversion'
      },
      error_modal: {
        sign: {
          header: 'Medicinen kunde inte signeras',
          body: 'Patienten har nyligen ångrat att den lade till denna medicin. Kontakta patienten vid frågor.'
        },
        cancel: {
          header: 'Medicinen redan utsatt',
          body: 'Medicinen som du försökte sätta ut är redan utsatt.'
        }
      },
      medication_option: {
        name: 'Läkemedelsnamn',
        form: 'Läkemedelsform',
        strength: 'Styrka',
        atc_code: 'ATC-kod',
        package_content: 'Förpackningsstorlek',
        approval_date: 'Godkännandedatum',
        unregistered_date: 'Avregistreringsdatum',
        sales_stop: 'Säljstopp'
      },
      vara_search: {
        search_vara: 'Sök i VARA',
        atc_note:
          'Om fabrikatet av läkemedlet är okänt, välj motsvarande generiskt läkemedel och välj "Tillverkare okänd" i nästa steg.',
        vara_match: 'VARA-matchad medicin',
        patient_information: 'Av patient angiven medicin',
        which_information: 'Vilken information vill du komplettera med?',
        manufacturer_known: 'Tillverkare känd',
        manufacturer_unknown: 'Tillverkare okänd',
        fill_in_all_information: 'Komplettera med all information i förhandsgranskningen ovan',
        fill_in_all_information_note: 'Detta ersätter all information patienten fyllt i manuellt. ATC-kod ingår.',
        fill_in_all_information_note_caregiver: 'Detta ersätter all information du fyllt i manuellt. ATC-kod ingår.',
        fill_in_atc_code_only: 'Endast ATC-kod, styrka och läkemedelsform hämtas',
        fill_in_atc_code_only_note:
          'ATC-koder används för att kunna veta exakt vilka preparat patienten står på och göra databearbetning.',
        cannot_find_header: 'Hittar du inte medicinen?',
        google_search_1: 'Testa att göra en ',
        google_search_2: 'sökning på Google',
        google_search_3: 'Den vanligaste orsaken är att preparatet är väldigt felstavat.',
        not_in_vara_header: 'Fanns inte preparatet i VARA?',
        not_in_vara_body:
          'Tänk på att naturläkemedel och kosttillskott inte ska läggas till i medicinlistan. Du har möjlighet att lägga till medicinen manuellt efter att ha angivit orsak nedan.',
        reason_foreign_medicine: 'Detta är ett utländskt preparat',
        reason_other: 'Övrigt, ange vad:'
      },
      base: {
        atc_code: 'ATC-kod',
        npl_pack_id: 'NPLpack-ID',
        name: 'Namn',
        dosage_regimen: 'Dosering',
        form: 'Läkemedelsform',
        strength: 'Styrka',
        when_needed: 'Vid behov',
        reason: 'Orsak'
      },
      medicine_extras: {
        supplements: 'Inga naturläkemedel eller kosttillskott.',
        medicine_changes: 'Inga svar på anamnesfrågorna om läkemedel.'
      },
      patient_requested_prescription: 'Patienten har efterfrågat receptförskrivning: "%{medicineName}"',
      patient_requested_prescription_warning:
        'Patienten har efterfrågat receptförskrivning. Ingen medicin har dock förskrivits via Alfa e-recept. Signera ändå?',
      generic_error: 'Något gick fel.'
    },
    overview: {
      no_phone_number: 'Inget telefonnummer angett',
      no_email: 'Ingen e-postadress angiven',
      height: 'Längd',
      weight: 'Vikt',
      waist_size: 'Midjemått',
      measurement_average: 'Medelblodtryck',
      bp_goal: 'Målblodtryck',
      risk_profile_header: 'Riskprofil',
      goal_header: 'Målsättning',
      values_header: 'Mått och värden',
      risk_profile_empty: 'Ingen riskprofil ifylld.',
      goal_empty: 'Ingen målsättning ifylld.',
      allergy_header: 'Annan allergi/överkänslighet',
      oversensitivity_header: 'Läkemedelsallergi',
      allergy_comments_header: 'Läkarkommentarer',
      total_measurements: 'Antal mätningar',
      morning_measurements: 'Morgonmätningar',
      evening_measurements: 'Kvällsmätningar',
      measurements_plural: 'mätningar',
      measurements_singular: 'mätning',
      average_measurements_header: 'Medelvärden',
      mark_inactive: 'Markera patient som inaktiv',
      marked_inactive_success: 'Patient markerad som inaktiv.',
      marked_inactive_error: 'Kunde inte markera patient som inaktiv. Vänligen försök igen.',
      mark_inactive_title: 'Patienten kommer inte dyka upp i din ärendelista.',
      marked_inactive_already_title: 'Patienten kommer markeras som aktiv vid sin nästa inloggning.',
      blocked_alert: 'Patienten är blockerad och kan inte längre logga in i tjänsten.',
      migrated_alert: 'OBS! Patienten är migrerad till nya versionen av Clinic och kan inte hanteras i denna version.'
    },
    events: {
      header: 'Ärendehantering',
      active_events_header: 'Åtgärd krävs',
      handled_events_header: 'Hanterade ärenden',
      no_active_events_header: 'Inga aktiva ärenden att hantera',
      no_active_events: 'Inga ärenden att hantera.',
      no_handled_events: 'Ingen ärendehistorik.',
      handle_event_error: 'Kunde inte klarmarkera ärendet. Vänligen försök igen.',
      get_active_events_error: 'Kunde inte hämta aktiva ärenden.',
      snooze: {
        zero_days: 'Snooza 0 dagar (test)',
        one_day: 'Snooza en dag',
        three_days: 'Snooza tre dagar',
        one_week: 'Snooza en vecka'
      },
      snoozed_until: 'Snoozat till %{date}',
      was_snoozed_until: 'Snoozades till %{date}',
      confirm_handle_snoozed_errand:
        'Det här är ett snoozat ärende som kommer dyka upp i din ärendelista %{date}. Är du säker på att du vill klarmarkera redan nu?',
      confirm_handle_without_final_note:
        'Det verkar som att du försöker stänga ärendet utan att ha skrivit en slutanteckning. Tryck OK för att klarmarkera ändå, eller Avbryt för att eventuellt snooza ärendet istället.'
    },
    referrals: {
      error: 'Kunde inte ladda Info Solutions. Vänligen försök igen.',
      modal_header: 'Instruktioner för remisser',
      show_info: 'Visa instruktioner för remisser',
      unilabs_stockholm: 'Provtagning med Unilabs i Stockholm/Södertälje',
      unilabs_stockholm_body:
        'Behöver ni beställa kompletterande prover här väljer ni Unilabs Stockholm i LabPortalen. För patienten är det drop-in.',
      unilabs_goteborg: 'Provtagning med Unilabs i Göteborg, Lidköping, Falköping och Uddevalla',
      unilabs_goteborg_body:
        'Behöver ni beställa kompletterande prover här väljer ni Unilabs Eskilstuna/Skövde i LabPortalen. För patienten är det drop-in.',
      encia_aleris: 'Provtagning med Encia/SYNLAB Medilab',
      encia_aleris_body:
        'Oavsett vilken Encia-mottagning patienten ska gå till behöver patienten kontakta vår support för tidsbokning. Om ni behöver beställa kompletterande prover för en patient som tagit sina prover hos Encia ska ni välja <strong>SYNLAB Medilab</strong> i LabPortalen och därefter klicka på <strong>"Spara"</strong>. Ni ska alltså <strong>inte skicka remissen</strong>. <em>(Genom att ni sparar en remiss för SYNLAB Medilab kan personalen på Encia gå in och läsa den i LabPortalen.)</em>',
      skane: 'Provtagning inom Region Skåne',
      skane_body:
        'Vi skickar pappersremisser till alla patienter inom Region Skåne. Behöver ni beställa uppföljningsprover gör ni detta manuellt via LabPortalen och <strong>"Sparar"</strong> remissen. Därefter skickar vår support ut en uppföljningsremiss. Är det brådskande så meddela gärna vår support i Slackkanalen #customersupport.',
      wetterhalsans: 'Provtagning hos Wetterhälsans Vårdcentral, A6',
      wetterhalsans_body:
        'För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      cordinator: 'Provtagning hos Cordinator Linköping',
      cordinator_body:
        'För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      uddevalla: 'Provtagning hos Hälsobolaget i Uddevalla',
      uddevalla_body:
        'För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      hudiksvall: 'Provtagning hos Valmea Vård i Hudiksvall',
      hudiksvall_body:
        'För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      ostersund: 'Provtagning hos Veronicare AB i Östersund',
      ostersund_body:
        'För remiss gäller följande: <strong>Välj Unilabs Sörmland</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      rattvik: 'Provtagning hos LenaGo Health',
      rattvik_body:
        'För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      vaxjo: 'Provtagning hos VaccinF i Växjö',
      vaxjo_body:
        'För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      umea: 'Provtagning hos Aleris Specialistvård Renmarkstorget',
      umea_body:
        'För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      kiruna: "Provtagning hos AK's Estetik & Hälsa i Kiruna",
      kiruna_body:
        'För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.',
      create_referral_header: 'Skapa remiss',
      referral_region_missing: 'Patienten har ännu inte valt region. Ett regionval krävs för att kunna skapa remiss.',
      referral_is_sent_to: 'Remiss skickas till patientens angivna region (%{region})',
      referral_is_sent_to_region_se:
        'Patienten har valt ”Alternativen passar inte mig”. En fysisk remiss behöver skrivas ut och tas med till provtagningsenheten.',
      create_referral: 'Skicka remiss',
      send_to_patient: 'Skicka remiss till patient',
      prepare_referral: 'Spara remiss',
      select_package: 'Välj analyspaket',
      custom_package: 'Gör eget urvalspaket',
      historic_referrals_header: 'Skapade remisser',
      custom_historic_package: 'Eget urval',
      historic_referrals_button: 'Visa skapade remisser',
      package_missing: 'Provlista saknas.',
      region: {
        encia:
          'OBS! Denna patient har valt provtagning hos Encia i %{regionName}.<br />För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        unilabs:
          'OBS! Denna patient har valt provtagning hos Unilabs i %{regionName}.<br />För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och välj sedan <strong>"Spara och skicka"</strong>.',
        stockholm:
          'OBS! Denna patient har valt provtagning hos Unilabs i Stockholm.<br />För remiss gäller följande: <strong>Välj Unilabs Stockholm</strong> och välj sedan <strong>"Spara och skicka"</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        skane:
          'OBS! Denna patient har valt provtagning i Region Skåne.<br />För remiss gäller följande: <strong>Välj Region Skåne</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        jonkoping:
          'OBS! Denna patient har valt provtagning hos Wetterhälsans Vårdcentral, A6.<br />För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        linkoping:
          'OBS! Denna patient har valt provtagning hos Cordinator i Linköping.<br />För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        hudiksvall:
          'OBS! Denna patient har valt provtagning hos Valmea Vård i Hudiksvall.<br />För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        ostersund:
          'OBS! Denna patient har valt provtagning hos Veronicare AB i Östersund.<br />För remiss gäller följande: <strong>Välj Unilabs Sörmland</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        rattvik:
          'OBS! Denna patient har valt provtagning hos LenaGo Health i Rättvik.<br />För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        vaxjo:
          'OBS! Denna patient har valt provtagning hos VaccinF i Växjö.<br />För remiss gäller följande: <strong>Välj Unilabs Eskilstuna/Skövde</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        umea: 'OBS! Denna patient har valt provtagning hos Aleris Specialistvård Renmarkstorget i Umeå.<br />För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen.',
        sweden:
          'Den här patienten har valt övrig provtagning i Sverige. Patienten behöver skriva ut en fysisk remiss och ta med till en av regionens provtagningsenheter.<br /><strong>För att skapa en remiss måste du använda funktionen i beslutsstödet, du kan inte skapa en remiss i LabPortalen</strong>.',
        kiruna:
          "OBS! Denna patient har valt provtagning hos AK's Estetik & Hälsa i Kiruna.<br />För remiss gäller följande: <strong>Välj SYNLAB Medilab</strong> och <strong>SPARA remissen</strong>. Remissen ska <strong>inte skickas</strong>.<br />Du behöver göra detta val manuellt om du beställer uppföljningsprover via LabPortalen."
      }
    },
    notes: {
      notes_tab: 'Anteckningar',
      documents_tab: 'Dokument och bilder',
      day_notes_header: 'Daganteckningar',
      final_notes_header: 'Slutanteckningar',
      no_day_notes: 'Inga daganteckningar',
      no_final_notes: 'Inga slutanteckningar',
      no_documents: 'Inga dokument',
      no_images: 'Patienten har inte laddat upp några bilder.',
      patient_communication_tab: 'Patientkommunikation',
      show_in_chat: 'Visa föranledande chattkommunikation',
      show_full_note: 'Visa hela anteckningen',
      hide_full_note: 'Dölj anteckningen',
      show_my_notes: 'Visa endast mina anteckningar',
      send_to_chat: 'Skicka till chatt',
      upload_file: 'Ladda upp',
      preview_error: 'Kunde inte förhandsgranska dokumentet.',
      unsupported_filetype: 'Kan inte ladda upp vald filtyp. Endast PDF stöds.',
      filename_notice:
        'Tänk på att namnet på dokumentet kommer att synas för patienten om du skickar dokumentet i chatten.',
      edit_filename: 'Redigera dokumentnamn',
      content_type: 'Typ av dokument',
      no_file_selected: 'Ingen fil vald',
      member_files_error: 'Kunde inte hämta patientens filer.',
      page_of_num_pages: 'Sida %{currentPage} av %{numPages}',
      document_type: 'Dokumenttyp',
      uploaded_by: 'Uppladdat av %{uploaderName}',
      create_document: 'Skapa PDF',
      referring_doctor: 'Remitterande läkare',
      patient_details: 'Patientuppgifter',
      document_field: {
        referral_recipient: 'Remissmottagare',
        recipient: 'Mottagare',
        requested_examination: 'Önskad undersökning',
        diagnosis_and_query: 'Diagnos och frågeställning',
        diagnosis: 'Diagnos',
        anamnesis: 'Anamnes',
        anamnesis_summary: 'Sammanfattande hälso- och sjukdomshistoria',
        current_medications: 'Aktuella mediciner',
        verdict: 'Utlåtande'
      },
      has_unsigned_medications_warning: 'Det finns osignerade mediciner.',
      examination: {
        header: 'Undersökningar',
        volume_control: 'Ljudvolym',
        performed_at: 'Undersökningen slutfördes %{date}',
        performed_at_assessment: 'Testinspelningen slutfördes %{date}',
        performed_by_header: 'Undersökningen genomfördes av',
        performed_by_header_assessment: 'Testinspelningen genomfördes av',
        performed_by: '%{name} på %{facility}',
        comment: 'Kommentar från undersökning',
        comment_assessment: 'Kommentar från partneranvändare',
        no_comment: 'Ingen kommentar',
        no_examination: 'Inga undersökningar genomförda.',
        auscultation: {
          error: 'Det gick inte att hämta auskultationsinspelningarna',
          no_recording: 'Inspelning saknas',
          heart: {
            header: 'Hjärtauskultation',
            top_left: 'Övre vänster',
            top_right: 'Övre höger',
            bottom_left: 'Nedre vänster',
            bottom_right: 'Nedre höger'
          },
          lungs: {
            header: 'Lungauskultation',
            top_left: 'Apikalt vänster',
            top_right: 'Apikalt höger',
            mid_left: 'Midskapulärt vänster',
            mid_right: 'Midskapulärt höger',
            bottom_left: 'Basalt vänster',
            bottom_right: 'Basalt höger'
          }
        },
        ecg_pending: 'EKG har ännu inte laddats upp av partner.'
      }
    },
    confirm: {
      risk_profile: 'Säker på att du vill spara ny riskprofil?',
      member_goal: 'Säker på att du vill spara ny målsättning?'
    },
    comments: {
      header: 'PAS-anteckningar'
    }
  },
  inbox_view: {
    header: 'Mina ärenden',
    action_required: 'Alla ärenden',
    handled_events_header: 'Hanterade ärenden',
    sort_option: {
      latest: 'Senast inkomna',
      age: 'Äldst'
    },
    wait_time: 'Väntetid',
    events_error: 'Det gick inte att hämta ärenden.',
    events_empty: 'Det finns inga ärenden än.',
    handled_events_error: 'Det gick inte att hämta hanterade ärenden.',
    handled_events_empty: 'Det finns inga hanterade ärenden.',
    own_events_empty: 'Du har inga ärenden för närvarande.',
    own_handled_events_empty: 'Du har inga hanterade ärenden.',
    handled_date: 'Hanteringsdatum',
    handled_reason: 'Orsak',
    new_patient: 'Ny pat.',
    responsible_doctor: 'PAL',
    responsible_nurse: 'PAS',
    show_only_own_errands: 'Visa endast mina ärenden',
    show_all_errands: 'Visa alla ärenden',
    hide_missing_members: 'Dölj inaktiva',
    new_members_error: 'Det gick inte att hämta nya patienter.',
    new_members_empty: 'Det finns inga nya patienter utan PAL.',
    self_assign: 'Paxa patient',
    self_assign_and_navigate: 'Paxa och svara',
    new_members_header: 'Nya patienter',
    registration_date: 'Registreringsdatum',
    responsible_doctor_column: 'Gör mig till PAL',
    responsible_nurse_column: 'Gör mig till PAS',
    hide_new_members: 'Dölj',
    show_all_new_members: 'Visa alla nya patienter',
    fetch_handled_events: 'Hämta hanterade ärenden',
    dashboard: {
      greeting: 'Hej %{name}!',
      you_have: 'Du har ',
      total_patients: '%{numTotal} patienter, varav ',
      active_patients: '%{numActive} är aktiva.',
      hide: 'Dölj',
      show: 'Visa',
      error: 'Kunde inte hämta statistik.',
      no_patients_pal: 'Du är ännu inte PAL för några patienter.',
      no_patients_pas: 'Du är ännu inte PAS för några patienter.',
      upcoming_checkups: 'Inbokade kontroller',
      upcoming_checkups_label: 'Kontroller',
      note: 'Notera att dessa siffror är preliminära. Nybesök och avbokningar kan påverka siffrorna.'
    },
    errand_action: {
      mark_unread: 'Markera som oläst'
    },
    n_new_patients: 'nya patienter väntar på att bli paxade.'
  },
  unhandled_errands_view: {
    header: 'Ohanterade ärenden',
    empty: 'Inga ohanterade ärenden',
    error: 'Kunde inte hämta ohanterade ärenden.'
  },
  member_list_view: {
    header_active: 'Aktiva användare',
    header_inactive: 'Ej aktiva användare',
    filter_placeholder: 'Filtrera på namn/personnummer',
    error: 'Kunde inte hämta användarlista.',
    no_results: 'Inga patienter matchade kriteriet.',
    showing_x_of_y: 'Visar %{currentPage} av %{totalCount}',
    include_non_pal: 'Inkludera även patienter jag inte är %{caregiverAbbr} för',
    not_active_yet: 'Ej aktiv ännu',
    column_headers: {
      name: 'Namn',
      personal_number: 'Personnummer',
      caregiver: '%{caregiverAbbr}',
      status: 'Status',
      source: 'Källa'
    }
  },
  unassigned_patients_view: {
    header: 'Nya patienter',
    empty: 'Det finns inga nya patienter att paxa för tillfället.',
    undiagnosed_title: 'Utan diagnos',
    status: {
      new: 'Ny patient',
      new_undiagnosed: 'Ny pat. (UD)',
      old: 'Från PAL'
    }
  },
  prioritized_errands_view: {
    header: 'Mina prioriterade ärenden',
    list_header: 'Prioriterade ärenden',
    empty: 'Du har inga prioriterade ärenden för tillfället.',
    filtered_empty: 'Inga ärenden matchade filtreringen.',
    show_all: 'Visa alla prioriterade ärenden'
  },
  active_consultations_view: {
    header: 'Mina aktiva konsultationer',
    list_header: 'Aktiva konsultationer',
    empty: 'Du har inga aktiva konsultationer för tillfället.',
    filtered_empty: 'Inga konsultationer matchade filtreringen.',
    show_all: 'Visa alla aktiva konsultationer'
  },
  patient_search_view: {
    header: 'Patientsök'
  },
  login_view: {
    login_with: 'Logga in med Mobilt BankID',
    login_failed: 'Inloggningen misslyckades',
    login: 'Logga in',
    instruction1:
      'Starta BankID-appen i din mobil och tryck på <strong>Skanna QR-kod</strong>. Rikta kameran mot QR-koden här ovanför.',
    instruction2: 'Skriv in din säkerhetskod i BankID-appen och välj <strong>Identifiera</strong>.',
    cancel_login: 'Avbryt inloggning'
  },
  referrals_view: {
    referrals: 'Remisser',
    lab_results: 'Provsvar',
    region_reminder: 'OBS! Denna patient har tidigare tagit sina prover hos:',
    region_reminder_manually: 'Du behöver göra samma val manuellt om du beställer uppföljningsprover via LabPortalen.'
  },
  decision_support_view: {
    onboarding: {
      header: 'Onboarding'
    },
    patient_not_ready: {
      header: 'Patienten är inte redo för sin inplanerade läkarkontroll',
      nudge_and_snooze: 'Uppmuntra patient och snooza ärende',
      snooze: 'Snooza ärende',
      snooze_and_send_message: 'Skicka meddelande och snooza ärende',
      measurements: {
        none: 'Patienten har inte gjort några mätningar de senaste två veckorna',
        single: 'Patienten har gjort 1 mätning de senaste två veckorna',
        multiple: 'Patienten har gjort %{numMeasurements} mätningar de senaste två veckorna'
      },
      anamnesis: {
        none: 'Patienten har inte svarat på hälso- och sjukvårdsfrågorna',
        partial: 'Patienten har delvis svarat på hälso- och sjukvårdsfrågorna',
        all: 'Patienten har svarat på hälso- och sjukvårdsfrågorna'
      },
      mark_as_ready_for_doctor: 'Klarmarkera patient som redo för läkarkontroll',
      chat_input_header: 'Skicka meddelande i chatten',
      chat_message_notice:
        'OBS! Om meddelanderutan lämnas tom så kommer ärendet att snoozas utan att ett meddelande skickas till patienten.',
      event_info: 'Ärendet visas igen om 4 dagar om patienten fortfarande inte är redo.',
      prefilled_chat_message: {
        not_prepared_at_all:
          'Hej! Inför din läkarkontroll behöver du uppdatera svaren på de utvalda hälso- och sjukvårdsfrågorna samt mäta ditt blodtryck. Du behöver mäta ditt blodtryck efter avslappning, både på morgonen och på kvällen, helst under flera dagar. Jag skjuter på din läkarkontroll några dagar så att du hinner förbereda dig.\n\nVänliga hälsningar,',
        no_measurements:
          'Hej! Inför din läkarkontroll behöver du mäta ditt blodtryck. Du behöver mäta ditt blodtryck efter avslappning, både på morgonen och på kvällen, helst under flera dagar. Jag skjuter på din läkarkontroll några dagar så att du hinner förbereda dig.\n\nVänliga hälsningar,',
        unanswered_questions:
          'Hej! Inför din läkarkontroll behöver du uppdatera svaren på de utvalda hälso- och sjukvårdsfrågorna. Jag skjuter på din läkarkontroll några dagar så att du hinner förbereda dig.\n\nVänliga hälsningar,'
      },
      existing_draft_notice:
        'Det finns ett aktivt utkast i chatten. Vänta tills det har skickats, eller sudda ut texten ovan om du bara vill snooza ärendet utan att skicka ett meddelande.'
    },
    physical_examination_referral: {
      header: 'Fysisk undersökning',
      recommendation: 'Patienten rekommenderas fysisk undersökning hos:',
      aleris: 'Aleris Hälsa',
      other_clinic: 'Listad vårdcentral',
      partner_button: 'Skicka instruktioner för fysisk undersökning',
      other_clinic_button: 'Skicka remiss och instruktioner för fysisk undersökning',
      referred_at: 'Remitterad %{date}.',
      referred_partner: 'Inväntar resultat från partner.',
      referred_other_clinic: 'Inväntar resultat från vårdcentral.',
      create_referral: 'Skapa remiss'
    },
    summary_header: '1. Sammanfattande bedömning',
    summary_error: 'Kunde inte hämta sammanfattande bedömning.',
    measurement_status_error: 'Kunde inte hämta vikt, BMI, midjemått.',
    blood_pressure_summary_error: 'Kunde inte hämta blodtrycksdata.',
    lab_results_error: 'Kunde inte hämta labbprover.',
    status_header: '2. Aktuell status',
    risk_profile_section_header: '3. Riskprofil och behandlingsmål',
    risk_profile_error: 'Kunde inte hämta riskprofil.',
    risk_profile_factors_header: 'Påverkningsbara riskfaktorer',
    risk_profile_factors_empty: 'Det finns inga påverkningsbara riskfaktorer.',
    show_history: 'Visa historik',
    hide_history: 'Dölj historik',
    no_history: 'Ingen historik.',
    new_patient_response: 'Nya svar från patient',
    removed_patient_response: 'Svar borttagna av patient',
    modified_patient_response: 'Uppdaterade svar från patient',
    new_response: 'Nytt svar',
    previous_response: 'Tidigare svar',
    show_full_list: 'Visa fullständig lista',
    hide_full_list: 'Dölj fullständig lista',
    no_changes_since_signature: 'Inga ändringar sedan föregående signering.',
    signed_by: 'signerad av',
    edit_row_header: 'Redigera',
    doctors_comment: 'Läkarens kommentar',
    add_comment: 'Skriv kommentar',
    history_header: 'Historik',
    day_notes_header: 'Relevanta daganteckningar',
    period_header: 'Visar period',
    show_all_lab_data: 'Visa samtliga labbdata',
    hide_lab_data: 'Dölj labbdata',
    lab_data_header: 'Labbprover',
    lab_test_type: 'Provtyp',
    show_lab_evaluation: 'Visa tolkning',
    goal_value: 'Mål',
    recommendation: 'Rekommendation',
    show_recommendation: 'Visa rekommendation',
    create_final_note_and_sign_report: 'Skriv slutanteckning och signera',
    sign_report: 'Signera',
    create_final_note: 'Skriv slutanteckning',
    sign_report_error: 'Det gick inte att signera beslutsstödet.',
    cannot_create_final_note: 'Det går inte att skriva slutanteckning för denna patient just nu.',
    not_all_required_questions_answered: 'Alla obligatoriska anamnesfrågor har inte besvarats.',
    sign_report_success: 'Beslutsstöd signerat!',
    goal_value_footnote: 'Målvärde baserat på en individuell SCORE-bedömning.',
    not_signable_header: 'Information saknas:',
    sign_with_unsigned_medications_warning:
      'Varning! Du försöker signera beslutsstödet trots att det finns osignerade mediciner.',
    signing_disabled_member_source:
      'Slutanteckningar är ett faktureringsunderlag för Region Sörmland och skapas inte för patienter från',
    required_field_missing: {
      measure_frequency: 'Mätfrekvens måste väljas innan beslutsstödet kan signeras.',
      checkup: 'Uppföljning måste anges.'
    },
    final_note_content: {
      treatment: 'Behandling av hypertoni',
      patient: '%{gender}, %{age} år, sysselsättning: %{occupation}',
      goal_bp: 'Målblodtryck: < %{systolic}/%{diastolic} mmHg',
      current_average_bp: 'Aktuellt medelblodtryck: %{average}',
      average_at_last_final_note: 'Medelblodtryck vid föregående slutanteckning %{date} (%{reason}): %{average}',
      heredity: 'Ärftlighet: %{heredity}',
      previous_diseases: 'Tidigare sjukdomar: %{diseases}',
      current_diseases: 'Nuvarande sjukdomar: %{diseases}',
      risk_factors: 'Aktuella påverkningsbara riskfaktorer: %{riskFactors}',
      recommended_lifestyle_changes: 'Rekommenderade livsstilsråd: %{recommendations}',
      medications: 'Nuvarande medicinering: %{medications}',
      allergies: 'Allergier: %{allergies}',
      planned_checkup: 'Planerad extra kontroll: %{date}',
      planned_checkups: 'Planerade extra kontroller: %{dates}',
      planned_three_month: 'Planerad 3-månaderskontroll: %{date}',
      planned_yearly: 'Planerad årskontroll: %{date}',
      no_planned_checkup: 'Ingen bokning',
      next_nurse_checkup_header: 'Nästa sjuksköterskekontroll',
      next_doctor_checkup_header: 'Nästa läkarkontroll',
      assessment: 'Bedömning: %{assessment}'
    },
    body_position: {
      unknown: 'Okänd',
      lyingDown: 'Liggande',
      semiRecumbent: 'Halvliggande',
      sitting: 'Sittande',
      standing: 'Stående'
    },
    anamnesis_updates: {
      header: 'Anamnesförändringar',
      header_threeMonthCheckup: 'Uppdaterad anamnes inför 3-månaderskontroll',
      header_yearlyCheckup: 'Uppdaterad anamnes inför årskontroll',
      changed_answers: 'ändrade svar',
      changed_answer: 'ändrat svar',
      new_info: 'Ny upplysning',
      new_answer: 'Nytt svar',
      previous_answer: 'Tidigare svar',
      current_answer: 'Aktuellt svar',
      show_unchanged_answers: 'Visa oförändrade svar',
      hide_unchanged_answers: 'Dölj oförändrade svar',
      question_never_answered: 'Frågan har aldrig besvarats.',
      unanswered: 'Obesvarad',
      unanswered_questions: 'obesvarade frågor',
      unanswered_question: 'obesvarad fråga',
      no_changes_all_answered: 'Alla frågor besvarade',
      show_older: 'Visa äldre anamnesförändringar',
      hide_older: 'Dölj äldre anamnesförändringar'
    },
    current_status_section: {
      measurement_type: 'Typ av mätning',
      latest_result: 'Senaste resultat',
      standing_measurement: 'Mätning i stående',
      symptomatic_measurement: 'Symptommätning',
      show_all_standing_measurements: 'Visa alla mätningar i stående',
      show_all_symptomatic_measurements: 'Visa alla symptommätningar',
      no_standing_measurements: 'Det finns inga stående mätningar.',
      no_symptomatic_measurements: 'Det finns inga symptommätningar.',
      hide_standing_measurements: 'Dölj stående mätningar',
      hide_symptomatic_measurements: 'Dölj symptommätningar'
    },
    risk_profile_section: {
      column_headers: {
        assessment: 'Bedömning',
        based_on_answers: 'Baserat på patientens svar',
        current_status: 'Aktuell status',
        status_at_previous_recommendation: 'Status vid senaste rekommendation',
        previous_recommendation: 'Tidigare rekommendation'
      },
      results_week_by_week: 'Resultat vecka för vecka',
      answered_at: 'Besvarades',
      no_risk_factor: 'Ingen aktuell riskfaktor',
      minutes_per_week: 'min/vecka',
      high_intensity_training: 'Högintensiv träning',
      low_intensity_training: 'Måttligt intensiv träning',
      members_goal: 'Patientens målsättning',
      lose: 'Gå ner',
      starting_weight: 'Startvikt',
      end_weight: 'Slutvikt',
      total_weight_loss: 'Total viktförändring',
      results_week_by_week_in_kg: 'Resultat vecka för vecka (i kg)',
      show_results_in_graph: 'Visa resultat i en graf',
      not_updated: 'Ej uppdaterad sedan senaste kontroll',
      weight_label: 'Vikt, kg',
      no_risk_profile: 'Ingen riskprofil tillgänglig',
      no_risk_factors: 'Inga påverkningsbara riskfaktorer',
      answered: 'Besvarades',
      recommended: 'Rekommenderades',
      no_reported_weight_change: 'Ingen inrapporterad viktförändring.',
      blood_pressure_goals_saved: 'Målblodtryck och mätfrekvens sparade.',
      blood_pressure_goals_save_error: 'Målblodtryck och mätfrekvens kunde inte sparas.'
    },
    treatment_goals_section: {
      header: '4. Behandlingsmål',
      individual_goals_header: 'Individuella behandlingsmål',
      treatment_goals: 'Behandlingsmål',
      results: 'Rekommenderad målsättning',
      copy_goal: 'Kopiera målsättning',
      error: 'Kunde inte hämta behandlingsmål.',
      empty: 'Det finns inga behandlingsmål att välja.',
      edit_bp_goal: 'Redigera målblodtryck',
      bp_goal_lead_text: 'Individanpassat målblodtryck till nästa uppföljning',
      measurement_frequency_lead_text: 'Mätfrekvens',
      measurement_frequency_1: 'Mät minst en gång per vecka',
      measurement_frequency_1_explanation: 'Patienten ligger bra till (<135/85), fortsatt bevakning.',
      measurement_frequency_1_generated_response:
        'Ditt blodtryck ligger på en bra nivå. Forsätt att mäta minst en gång i veckan, gärna oftare. Växla gärna mellan olika tider på dagen.',
      measurement_frequency_2: 'Mät 2-3 gånger per vecka',
      measurement_frequency_2_explanation:
        'Patienten ligger något utanför målvärdet eller stor variation i blodtrycket.',
      measurement_frequency_2_generated_response:
        'Ditt blodtryck ligger en bit ifrån målblodtrycket. Fortsätt mäta 2-3 gånger i veckan, gärna oftare om du vill. Växla gärna mellan olika tider på dagen.',
      measurement_frequency_4: 'Mät minst 4 gånger per vecka',
      measurement_frequency_4_explanation:
        'Patienten ligger utanför målvärden och bör mäta oftare till nästa uppföljning.',
      measurement_frequency_4_generated_response:
        'Ditt blodtryck ligger ganska långt ifrån målblodtrycket. Jag vill att du fortsätter att mäta ditt blodtryck minst 4 gånger per vecka. Variera gärna dina mätningar mellan morgon och kväll samt mellan vardag och helgdag.',
      measurement_frequency_7: 'Mät dagligen',
      measurement_frequency_7_explanation:
        'Patienten bör mäta dagligen tills nästa uppföljning, t.ex. efter medicinjustering.',
      measurement_frequency_7_generated_response:
        'Eftersom din medicinering har justerats är det bra om du mäter ditt blodtryck dagligen närmaste tiden. Variera gärna mellan morgon och kväll, gör gärna även en mätning mitt på dagen och en mätning i stående.',
      bp_goals_header: 'Målblodtryck och frekvens för mätning till nästa uppföljning',
      member_accepted_challenge: 'Patient antog utmaning',
      member_completed_challenge: 'Patient genomförde utmaning',
      member_not_accepted_challenge: 'Patient har inte antagit utmaning.',
      generated_response_header: 'Genererad målsättning till patient:',
      generated_response_info:
        'När mindre än 3 veckor återstår till en kommande läkarkontroll ombeds patienten att registrera minst en mätning på morgonen och minst en mätning på kvällen, men gärna fler mätningar för bättre behandlingsunderlag.',
      disabled: 'Funktionen stöds inte av patientens app. Meddela frekvens för mätning i ett chattmeddelande.'
    },
    notes_section: {
      next_checkup: 'Nästa kontroll',
      next_reminder: 'Nästa påminnelse',
      upcoming_follow_up: 'Kommande uppföljning',
      last_signed: 'Senaste signering',
      change_follow_up_date: 'Ändra uppföljningsdatum',
      notes_since_last_followup_singular: 'Det finns %{numNotes} daganteckning sedan föregående signering.',
      notes_since_last_followup_plural: 'Det finns %{numNotes} daganteckningar sedan föregående signering.',
      note_history_header: 'Daganteckningshistorik',
      error: 'Kunde inte hämta status.',
      no_checkups: 'Inga uppföljningar bokade.',
      edit_checkup_date: 'Redigera datum',
      edit_checkup_title: 'Redigera uppföljning',
      edit_checkup: 'Redigera påminnelse',
      delete_checkup_title: 'Ta bort uppföljning',
      confirm_deletion: 'Säker på att du vill ta bort uppföljningen?',
      save_new_checkup_date: 'Spara nytt datum',
      save_checkup: 'Spara påminnelse',
      selected_checkup_date: 'Valt uppföljningsdatum'
    },
    medication_section: {
      header: '4. Läkemedelsbehandling',
      error: 'Det gick inte att hämta aktuell medicinlista.',
      empty: 'Det finns inga aktuella mediciner.',
      current_medication_header: 'Aktuell medicinlista',
      medication_link: 'Hantera mediciner',
      unsigned_medication_list: 'Osignerad medicinlista',
      other_doctor: 'Annan läkare',
      column_headers: {
        issued_by: 'Utfärdat av',
        medication_name: 'Läkemedelsnamn',
        strength: 'Styrka',
        medication_type: 'Läkemedelsform',
        medication_dose: 'Läkemedelsdosering',
        prescription: 'Förskrivning'
      }
    },
    checkup_section: {
      header: '5. Uppföljning',
      error: 'Kunde inte hämta påminnelseorsaker.',
      checkup_reasons_empty: 'Det finns inga påminnelseorsaker.',
      choose_date: 'Välj datum',
      checkup_period_two_weeks: '2 veckor',
      checkup_period_three_weeks: '3 veckor',
      checkup_period_one_month: '1 månad',
      checkup_period_three_months: '3 månader',
      checkup_period_six_weeks: '6 veckor',
      checkup_period_six_months: '6 månader',
      checkup_period_year: 'Årskontroll',
      checkup_period_sub_header: 'Boka kontroll',
      return_visit: 'Återbesök',
      return_visit_approaching: 'OBS! Det närmar sig patientens årskontroll. Förra årskontrollen var',
      checkup_reason: 'Orsak till påminnelse',
      epicrisis_header: 'Slutanteckning',
      confirm_epicrisis_header: 'Bekräfta slutanteckning',
      sign_button_text: 'Signera',
      book_return_visit: 'Boka återbesök',
      previous_bookings: 'Tidigare bokningar',
      checkup_will_be_booked: 'Kontroll bokas',
      three_month_visit_will_be_booked: '3-månaderskontroll bokas',
      yearly_visit_will_be_booked: 'Årskontroll bokas',
      planned_checkup: 'Planerad kontroll',
      planned_visit: 'Planerat återbesök',
      reason_header: 'Orsak',
      treatment_reason: 'Behandlingsorsak',
      diagnosis_code: 'Diagnoskod',
      treatment_code: 'Åtgärdskod',
      checkup_explanation:
        'Exempelvis om du gjort en medicinförändring eller inväntar mer information. Patienten får ingen återkoppling i appen såvida du inte ändrat mätningsfrekvens under',
      visit_explanation:
        'När mindre än 3 veckor återstår till kontrollen uppmanas patienten att göra 7 morgon- och kvällsmätningar. Bokas en årskontroll kommer patienten även bli ombedd att ta nya rutinprover.',
      disabled_dates_explanation:
        'Notera att det inte går att boka en kontroll på samma dag som en redan inbokad kontroll.',
      same_day_warning:
        'OBS - du håller på att boka en kontroll på ett datum där det redan finns en bokning. Detta ska bara göra i särskilda fall, så som vid medicinskt brådskande ärenden. Överväg att lägga din bokning nästkommande dag om möjligt.',
      yearly_checkup_already_booked: 'Det finns redan en bokat årskontroll.',
      three_month_checkup_already_booked: 'Det finns redan en bokat 3-månaderskontroll.',
      treatment_reason_default: 'Behandling av hypertoni.',
      first_visit_sub_header:
        'Uppföljning behöver inte bokas vid denna första signering - ärende om att titrering är klar kommer automatiskt.',
      num_bookings_in_week: 'kontroller inplanerade under den valda veckan.',
      bookings_empty: 'Inga',
      search_diagnosis_code: 'Sök diagnoskod på internetmedicin.se',
      opens_new_tab: 'öppnas i ny flik',
      icd_selection: 'Snabbval',
      checkup_timeline: {
        header: 'Bokningar',
        empty: 'Patienten har inga inplanerade eller tidigare kontroller.',
        caregiver_type: {
          doctor: 'Läkare',
          nurse: 'Sjuksköterska'
        },
        delete_header: 'Ange orsak till borttagningen',
        exemption_card_expiration_warning: 'OBS: Patientens frikort slutar gälla %{validUntil}.',
        patient_checkup_notification_warning:
          'OBS: Patienten har redan meddelats om att kontrollen kommer att ske i närtid.',
        confirm_delete_reminder: 'Är du säker på att du vill ta bort påminnelsen?',
        edit_checkup: 'Ändra datum',
        edit_reminder: 'Ändra datum/orsak',
        total_checkups_in_selected_week: 'Totalt antal bokningar under den valda veckan: %{numCheckups}',
        member_actions_requested:
          'Patienten har ombetts förbereda sig inför läkarkontrollen via e-post, notiser och på startsidan i appen. SMS skickas en vecka innan läkarkontrollen om patienten inte förbereder sig.'
      },
      new_checkup_booking: {
        add_booking: 'Lägg till kontroll/påminnelse',
        caregiver_type_header: 'För vem ska kontrollen bokas?',
        checkup_or_reminder: 'Är det en kontroll eller en påminnelse?',
        checkup_type_header: 'Vilken typ av kontroll?',
        reminder_header: 'Vad vill du bli påmind om?',
        checkup_category: {
          checkup: 'Kontroll',
          reminder: 'Påminnelse'
        },
        patient_fee_notice: 'OBS: Kontrollen kan kräva patientavgift.',
        recent_payment_warning: 'Notera att patienten nyligen betalade patientavgift.',
        close_booking_warning: 'Notera att det valda datumet ligger nära en redan inbokat kontroll.'
      },
      treatment_year_overview: {
        header: 'Rekommenderat behandlingsår',
        year_1: {
          header: 'År 1',
          checkup_0: 'Första sjuksköterskekontrollen',
          checkup_1: 'Första läkarkontrollen',
          checkup_2: '2-veckorskontroll <strong>sjuksköterska</strong>',
          checkup_3: '6-veckorskontroll <strong>läkare</strong>',
          checkup_4: '3-månaderskontroll <strong>läkare</strong>',
          checkup_5: 'Sjuksköterskekontroll 2 v. inför halvårskontroll',
          checkup_6: '6-månaderskontroll <strong>läkare</strong>',
          checkup_7: '9-månaderskontroll <strong>läkare</strong>',
          checkup_8: 'Sjuksköterskekontroll 2 v. inför årskontroll',
          checkup_9: 'Årskontroll <strong>läkare</strong>'
        },
        year_2: {
          header: 'År 2',
          checkup_0: '3-månaderskontroll <strong>läkare</strong>',
          checkup_1: 'Sjuksköterskekontroll 2 v. inför halvårskontroll',
          checkup_2: '6-månaderskontroll <strong>läkare</strong>',
          checkup_3: '9-månaderskontroll <strong>läkare</strong>',
          checkup_4: 'Sjuksköterskekontroll 2 v. inför årskontroll',
          checkup_5: 'Årskontroll <strong>läkare</strong>'
        }
      }
    },
    final_note: {
      role_transfer_header: 'Överlämning till sjuksköterska',
      role_transfer_default_option: 'Välj när patienten ska lämnas över',
      role_transfer_delay: {
        delay_0: 'Omgående',
        delay_7: 'Om 1 vecka',
        delay_14: 'Om 2 veckor'
      },
      patient_will_be_transferred_to_nurse: 'Patienten lämnas över till sjuksköterska %{delay}.',
      patient_fee_header: 'Var det här en extrakontroll för receptförnyelse?',
      patient_fee_not_required: 'Ja, denna kontroll är kostnadsfri för patienten.',
      patient_fee_required: 'Nej, patienten betalar en patientavgift på 100 kr.',
      patient_fee_required_confirmation_yes: 'Patienten betalar 100 kr i patientavgift.',
      patient_fee_required_confirmation_no: 'Patienten betalar ingen patientavgift.',
      standard_assessment_disabled_title:
        'Du har gjort ändringar i texten ovan. Standardbedöming kan inte längre väljas.',
      select_standard_assessment: 'Välj standardbedömning',
      bp_normal: 'Bra blodtrycksnivå',
      bp_elevated_medication_unchanged: 'Förhöjd medelblodtrycksnivå - oförändrad medicinering',
      bp_elevated_medication_changed: 'Förhöjd medelblodtrycksnivå - medicinjustering',
      other_treatment_codes: 'Ytterligare diagnos-/åtgärdskod'
    },
    overview_section: {
      no_measurements: 'Inga mätningar',
      no_logins: 'Inga inloggningar',
      last_measurement: 'Senaste mätning',
      last_login: 'Senast inloggad',
      payment_exemption_header: 'Frikort',
      payment_exemption: '%{cardNumber} (giltigt till %{validUntil})',
      payment_exemption_empty: 'Inget frikort',
      active_caregiver: 'Aktiv vårdgivare',
      account_created: 'Kontot skapades',
      last_n_measurements_header: 'Senaste %{n} mätningarna (av %{total})',
      last_measurement_header: 'Endast 1 mätning gjord',
      current_monitor_header: 'Blodtrycksmätare',
      current_monitor_tooltip: 'Indikerar vilken mätare som användes vid senaste mätningen.',
      patient_status: 'Patientstatus',
      final_notes_in_last_year_header: 'Besök senaste 12 månaderna',
      doctor_notes: '%{count} läkarbesök',
      nurse_notes: '%{count} sjuksköterskebesök'
    },
    show_detailed_graph: 'Visa detaljerad graf',
    last_three_months_header: 'Blodtrycksmätningar senaste 3 månaderna',
    variation_systolic: 'Variation systoliskt',
    variation_diastolic: 'Variation diastoliskt',
    average_last_three_months: 'Medel senaste 3 månader',
    special_measurements_header: 'Specialmätningar',
    orthostatic_hypotension: 'Förekomst av ortostatisk hypotension',
    no_lab_results: 'Det finns inga labbprover.',
    no_trend_data: 'Ingen medelvärdesdata tillgänglig.',
    legend_systolic: 'Systoliskt, mmHg',
    legend_diastolic: 'Diastoliskt, mmHg',
    legend_heartrate: 'Puls, BPM',
    legend_systolic_goal: 'Systoliskt mål',
    legend_diastolic_goal: 'Diastoliskt mål',
    legend_weight: 'Vikt',
    legend_weight_goal: 'Målvikt',
    weight_graph_header: 'Viktförändring',
    trend_period: {
      average: 'Medel',
      measurements: 'mätningar',
      number_of_measurements: 'Antal mätningar',
      average_morning: 'Medel morgon',
      average_evening: 'Medel kväll',
      average_day: 'Medel dag',
      average_night: 'Medel natt',
      median: 'Medianvärde',
      heartrate: 'Puls',
      trend_since_previous_period: 'Trend sedan föreg. intervall',
      important_event: 'Viktig händelse'
    },
    severities: {
      extralow: 'Extremt lågt',
      verylow: 'Mycket lågt',
      low: 'Lågt',
      slightlylow: 'Måttligt lågt',
      decreased: 'Sänkt',
      normal: 'Normalt',
      increased: 'Förhöjt',
      slightlyhigh: 'Måttligt högt',
      high: 'Högt',
      veryhigh: 'Mycket högt',
      extrahigh: 'Extremt högt'
    },
    support: {
      header: 'Supportärende',
      button: 'Skapa supportärende',
      history_header: 'Tidigare supportärenden för denna patient',
      history_error: 'Kunde inte hämta ärendehistorik.',
      history_empty: 'Inga tidigare supportärenden.',
      history_column: {
        issue: 'Ärende',
        created: 'Skapat',
        status: 'Status'
      },
      select_issue_type: 'Välj ärendetyp',
      status_closed: 'Stängt',
      status_open: 'Öppet',
      comments_empty: 'Inga uppdateringar.',
      issue: 'Ärende',
      updates: 'Uppdateringar'
    }
  },
  time: {
    chat_timestamp_format: 'YYYY-MM-DD \\k\\l. HH:mm'
  },
  sidebar: {
    profile: 'Profil',
    logout: 'Logga ut',
    release_notes: 'Release notes',
    tutorial: 'Tutorial',
    faq_clinic: 'FAQ Clinic',
    faq_medical: 'FAQ Medical',
    faq_support: 'FAQ Patientsupport',
    role: {
      caregiver: 'Leg. läkare',
      nurse: 'Leg. sjuksköterska',
      healthscientist: 'Hälsovetare'
    },
    change_profile_picture: 'Byt profilbild',
    menu_option: {
      inbox: 'Ärenden',
      unhandled: 'Ohanterade ärenden',
      active_patients_list: 'Patienter',
      inactive_patients_list: 'Ej aktiva',
      referrals: 'Remisser',
      kiosk_review: 'Kioskbedömningar',
      auscultation_assessments: 'Auskultationsbedömningar',
      search: 'Sök',
      my_patients: 'Mina patienter'
    }
  },
  profile_image_upload: {
    header: 'Uppdatera profilbild',
    header_new: 'Välj profilbild',
    header_confirm: 'Bekräfta profilbild',
    action: 'Spara bild',
    select_file: 'Välj fil',
    drag_here: 'eller dra en bild hit',
    preview: 'Förhandsgranska',
    app_preview_header: 'Exempel på hur bilden kommer se ut i dina patienters chatt:',
    webcam_action: 'Ta bild',
    webcam_header: 'Webbkamera',
    or: 'eller',
    take_webcam_photo: 'ta en bild med webbkameran',
    webcam_retry: 'Klicka här',
    camera_access_denied: 'Du måste tillåta kameran.',
    camera_access_try_again: 'när du gett Clinic tillgång till kameran.'
  },
  checkup: {
    reason: {
      choose_reason: 'Välj anledning till uppföljning',
      medication_adjustment: 'Medicinförändring',
      lifestyle_changes: 'Livsstilsförändring',
      awaiting_more_measurements: 'Inväntar fler mätvärden',
      awaiting_member_reply: 'Väntar på svar från patient',
      other: 'Övrigt',
      doctor_reminder: 'Påminnelse'
    },
    checkup_date: 'Datum för uppföljning',
    no_checkup_date: 'Inget datum valt',
    checkup: 'Uppföljning',
    checkup_booked: 'Kontroll bokad',
    save_checkup: 'Spara kontroll'
  },
  release_notes: {
    header: 'Ny version släppt',
    general_info:
      'En mjukvaruuppdatering har släppts sedan du senast loggade in. Klicka på länken nedan för att se vad som är nytt.',
    link_text: 'Release notes'
  },
  canned_response: {
    button: 'Snabbsvar',
    back: 'Tillbaka',
    free_text_alert: 'Du måste fylla i %{tag} innan meddelandet kan skickas.',
    search_placeholder: 'Sök snabbsvar',
    no_search_results: 'Inga snabbsvar hittades som matchade sökkriteriet.'
  },
  maintenance_mode: {
    banner: 'Systemet kommer att försättas i underhållsläge om %{remainingTime}. Slutför eventuellt arbete.',
    deploy_in_progress_header: 'Underhållsarbete pågår',
    deploy_in_progress_body:
      'Denna dialog kommer stängas automatiskt när systemet är uppe igen. Därefter kan du fortsätta arbeta i Clinic.',
    maintenance_warning_header: 'Systemet uppdateras strax',
    maintenance_warning_body:
      'Systemet kommer strax att försättas i underhållsläge. Under tiden underhållsarbetet pågår kommer du inte kunna arbeta i Clinic så slutför eventuellt pågående arbete nu.',
    maintenance_complete_header: 'Underhållsarbete slutfört',
    maintenance_complete_body: 'Du kan nu fortsätta arbeta i Clinic.'
  },
  session: {
    token_expiration_warning_header: 'Din session löper snart ut',
    token_expiration_warning_body:
      'Du kommer snart att bli utloggad. Stäng denna dialog för att fortsätta vara inloggad.'
  },
  patient_communication_view: {
    header: 'Patientkommunikation',
    error: 'Kunde inte hämta patientkommunikation.',
    empty: 'Ingen tidigare patientkommunikation finns.',
    empty_content: 'Inget innehåll kunde hittas.',
    modal_action: 'OK',
    modal_header: 'Innehåll',
    view_content: 'Visa innehåll',
    column_header: {
      date: 'Datum',
      description: 'Beskrivning',
      type: 'Kontaktsätt',
      content: 'Visa innehåll'
    },
    communication_type: {
      chat: 'Chat',
      email: 'Email',
      jira: 'Jira',
      push: 'Push',
      sms: 'SMS'
    }
  },
  payments_view: {
    header: 'Patientavgiftshistorik',
    error: 'Kunde inte hämta patientavgiftshistorik.',
    empty: 'Ingen betalningshistorik.',
    column_header: {
      checkup_type: 'Kontroll',
      payment_status: 'Betalningsstatus',
      date: 'Datum'
    }
  },
  program_timeline_view: {
    header: 'Tidslinje',
    get_programs_error: 'Kunde inte hämta program',
    no_active_program: 'Patienten har inget pågående program',
    program: 'Program',
    step: {
      completed: 'Slutförd',
      not_started: 'Ej påbörjad',
      ongoing: 'Pågående',
      started: 'Påbörjad'
    }
  },
  kiosk_assessment: {
    page_title: 'Bedöming av kioskpatienter',
    fetch_assessment_count_error: 'Det gick inte att hämta bedömningar.',
    fetch_assessment_error: 'Det gick inte att hämta bedömningen. Försök igen eller kontakta support.',
    leave_unhandled_warning: 'Bedömningen har ännu inte hanterats. Du hittar den under "%{section}".',
    open_dss: 'Öppna i beslutsstöd',
    summary: {
      header: 'Kioskbedömningar från samarbetspartners',
      waiting: 'nya patienter väntar på bedömning',
      empty: 'Inga kioskmätningar att bedöma just nu.',
      start_assessment: 'Starta granskning',
      start_error: 'Det gick inte att starta granskning.'
    },
    assessment_list: {
      caregiver_paused: {
        header: 'Mina pausade kioskbedömningar',
        header_all: 'Alla pausade kioskbedömningar',
        empty: 'Inga pausade bedömningar',
        error: 'Det gick inte att hämta pausade bedömningar'
      },
      caregiver_rejected: {
        header: 'Mina avfärdade kioskbedömningar',
        header_all: 'Alla avfärdade kioskbedömningar',
        empty: 'Inga avfärdade bedömningar',
        error: 'Det gick inte att hämta avfärdade bedömningar'
      },
      completed: {
        header: 'Mina godkända kioskbedömningar',
        header_all: 'Alla godkända kioskbedömningar',
        empty: 'Inga godkända bedömningar',
        error: 'Det gick inte att hämta godkända bedömningar'
      },
      assessment_in_progress: {
        header: 'Mina pågående kioskbedömningar',
        empty: 'Inga pågående bedömningar',
        error: 'Det gick inte att hämta pågående bedömningar'
      }
    },
    actions: {
      cancel_assessment: 'Släpp patient och gå tillbaka',
      cancel_assessment_paused: 'Låt någon annan göra denna bedömning',
      write_final_note: 'Skriv slutanteckning och återgå till startsidan',
      write_final_note_and_get_next: 'Skriv slutanteckning och granska nästa',
      pause_assessment: 'Pausa denna granskning',
      reject_assessment: 'Bedömning felaktig',
      error: {
        cancel: 'Det gick inte att avbryta granskningen.',
        pause: 'Det gick inte att pausa denna granskning.',
        reject: 'Det gick inte att avfärda denna granskning.',
        write_final_note: 'Det gick inte att skriva slutanteckning. Försök igen eller kontakta support.',
        get_next: 'Det gick inte att hämta nästa granskning.'
      },
      success: {
        pause: 'Granskningen pausades',
        reject: 'Granskningen avfärdades'
      }
    },
    all_assessments_completed: 'Alla patienter granskade! Återgår till startsidan...',
    assessment_meta: 'Denna mätsession genomfördes hos <strong>%{location}</strong> den <strong>%{date}</strong>.',
    assessment_language: 'Anamnes genomförd på:',
    unknown_location: 'okänt apotek',
    anamnesis_summary: 'Sammanfattning av anamnes',
    medical_assessment: 'Medicinsk bedömning',
    recommendation: 'Rekommendation',
    kiosk_reason: 'Kioskmätning',
    status: {
      completed: 'Granskad av %{name}',
      caregiver_paused: 'Pausad av %{name}',
      caregiver_rejected: 'Avfärdad av %{name}',
      assessment_in_progress: 'Granskas av %{name}'
    },
    btd_invite_type: {
      diagnosed_onboarding: 'Patienten fick information om att starta behandling hos Blodtrycksdoktorn',
      excluded: 'Patienten fick ingen inbjudan till Blodtrycksdoktorn',
      existing_patient: 'Existerande patient hos Blodtrycksdoktorn',
      undiagnosed_onboarding: 'Patienten fick information om att starta hypertoniutredning hos Blodtrycksdoktorn'
    },
    non_nurse_info: 'Endast SSK kan hantera kioskbedömningar'
  },
  auscultation_assessment: {
    page_title: 'Auskultationsbedömningar',
    assessment_header: 'Inspelningar för bedömning',
    list_header: 'Väntande auskultationsbedömningar',
    column: {
      facility: 'Partnermottagning',
      received: 'Inskickad'
    },
    error: {
      list_assessments: 'Det gick inte att hämta väntande bedömningar.'
    },
    no_assessments: 'Inga väntande bedömningar',
    status: {
      accepted: 'Testinspelning godkänd',
      rejected: 'Testinspelning underkänd'
    },
    rejection_modal: {
      header: 'Underkänn testinspelning',
      action: 'Underkänn',
      comment_header: 'Varför underkänns testinspelningen?'
    },
    accept_modal: {
      header: 'Godkänn testinspelning',
      action: 'Godkänn',
      comment_header: 'Frivillig feedback till partneranvändaren'
    }
  },
  journal: {
    caregiver_role: {
      doctor: 'Läkare',
      nurse: 'Sjuksköterska',
      dietician: 'Dietist',
      support: 'Support'
    },
    treatment_area: {
      hypertension: 'Hypertoni',
      obesity: 'Obesitas'
    },
    treatment_area_state: {
      investigation: 'Utredning',
      reactivation: 'Återaktivering',
      treatment: 'Behandling',
      titration: 'Titrering',
      not_started: 'Inte påbörjad',
      aborting: 'Begärt avslut',
      aborted: 'Avslutad',
      lifestyle_only: 'Endast livsstilsbehandling'
    },
    temporary_caregiver: {
      notice: 'Du är tillfällig vårdgivare för denna patient under perioden %{from} – %{to}',
      introduction_reminder:
        'OBS! Detta är ditt första möte med patienten. Kom ihåg att presentera dig i början av meddelandet.'
    },
    migration_status: {
      notice: 'Patienten har migrerats från tidigare version av Clinic %{from}'
    },
    treatment_panel: {
      message_type: {
        doctor: 'PAL-meddelande',
        nurse: 'PAS-meddelande',
        patient: 'Patientmeddelande'
      },
      program_state: {
        paused: 'Pausat',
        aborted: 'Avbrutet',
        ongoing: 'Pågående'
      },
      program_state_reason: {
        preparations_not_done: 'Förberedelser ej genomförda i tid',
        optional_preparations_not_done: 'Valfria förberedelser ej genomförda',
        other: 'Orsak ej specificerad',
        aborted_by_patient: 'Avbrutet av patient',
        aborted_by_admin: 'Avbrutet av support',
        aborted_by_caregiver: 'Avbrutet av vårdgivare'
      },
      preparation_reasons: {
        other: 'Annat',
        no_time: 'Har inte tid',
        no_available_time: 'Finns inga tider',
        too_long_distance: 'För långt avstånd',
        cannot_leave_home: 'Har ej möjlighet att ta mig hemifrån',
        not_relevant: 'Ej relevant',
        no_comment: 'Ingen kommentar',
        recently_done: 'Nyligen genomförd',
        more_time: 'Behöver med tid',
        preparations_not_done: 'Förberedelser ej genomförda',
        appointment_started: 'Kontroll startad'
      }
    },
    assessment_status: {
      unknown: 'Okänd',
      verified: 'Signerad',
      ready_for_verification: 'Osignerad',
      findings_available: 'Utredning avslutad',
      investigation_ongoing: 'Utredning pågår',
      signed: 'Signerad',
      rejected: 'Avvisad',
      waiting_for_conclusion_from_caregiver: 'Inväntar bedömning'
    },
    appointment: {
      edit_header: 'Redigera kontroll',
      cancel: 'Avboka',
      save_new_date: 'Boka om',
      state: {
        new: 'Redo att starta',
        ongoing: 'Pågående',
        completion: 'Slutförd',
        signed: 'Signerad',
        cancelled: 'Avbruten'
      }
    },
    work_item_type: {
      appointment: 'Kontroll',
      errand: 'Ärende',
      monitoring: 'Bevakning',
      program: 'Uppföljning'
    },
    patient_feedback: {
      header: 'Patientåterkoppling',
      send: 'Skicka',
      regenerate: 'Generera om 🤖',
      cancel: 'Avbryt',
      placeholder: 'Skriv din text här',
      is_generating: ' genereras...',
      error: 'Kunde inte generera patientåterkoppling.'
    },
    careplan_modal: {
      header: 'Välj behandlingsplan',
      save: 'Starta behandlingsplan'
    },
    recommended_actions: {
      modal_header: 'Lägg till åtgärder',
      modal_button: 'Spara',
      error: 'Kunde inte hämta rekommenderade åtgärder.'
    },
    encounter_summary: {
      error: 'Kunde inte hämta summering av vårdinsats.'
    },
    medical_record_category: {
      current_diseases: 'Aktuella sjukdomar',
      previous_diseases: 'Tidigare sjukdomar',
      allergies: 'Allergier',
      medicines: 'Mediciner',
      other_medical_information: 'Övrig läkemedelsinformation',
      nurse_notes: 'PAS-anteckningar',
      patient_anamnesis: 'Patientanamnes'
    },
    data_type: {
      frequency: {
        very_often: 'Mycket ofta',
        often: 'Ofta',
        sometimes: 'Ibland',
        rarely: 'Sällan',
        never: 'Aldrig',
        daily: 'Dagligen',
        monthly: 'Månatligen',
        yearly: 'Årligen',
        weekly: 'Varje vecka'
      }
    },
    assessment: {
      smoking_habits: {
        occasional_smoker: 'Röker då och då',
        smokes_cigarettes_daily: 'Röker cigaretter dagligen',
        smokes_cigarettes_daily_above_10: 'Röker cigaretter dagligen (fler än 10)',
        smokes_cigarettes_daily_above_20: 'Röker cigaretter dagligen (fler än 20)',
        smokes_hookah_daily: 'Röker vattenpipa dagligen',
        smokes_hookah_daily_30_min: 'Röker vattenpipa dagligen (30 min.)',
        smokes_hookah_daily_1_hour: 'Röker vattenpipa dagligen (1 timme)',
        smokes_cigars_daily_one: 'Röker 1 cigarr dagligen',
        smokes_cigars_daily_two: 'Röker 2 cigarrer dagligen',
        smokes_cigars_daily_above_two: 'Röker fler än 2 cigarrer dagligen',
        smokes_pipes_daily: 'Röker pipa dagligen',
        smokes_pipes_daily_above_5: 'Röker pipa dagligen (fler än 5)',
        smokes_pipes_daily_above_10: 'Röker pipa dagligen (fler än 10)',
        former_smoker: 'Före detta rökare',
        smoker: 'Rökare',
        non_smoker: 'Ickerökare'
      },
      snuff_consumption: {
        snuff_weekly_above_1: '1 – 3 dosor/vecka',
        snuff_weekly_above_3: '4 – 6 dosor/vecka',
        snuff_weekly_above_6: '7 eller fler dosor/vecka',
        228492003: 'Har aldrig varit snusare',
        228503001: 'Före detta snusare',
        449867007: 'Snusar, men inte dagligen',
        228504007: 'Snusar dagligen'
      }
    },
    appointment_activity: {
      new_message: 'Meddelande',
      appointment_completion_timed_out: 'Frågekomp. ej besvarad',
      appointment_completion_accepted: 'Redo för signering',
      appointment_completion_rejected: 'Frågor på avslut',
      conversation_timed_out: 'Uteblivet svar på meddelande',
      waiting_for_chat_message: 'Väntar på meddelande',
      waiting_for_appointment_completion_response: 'Väntar på svar',
      complete_appointment: 'Genomför kontroll',
      journal_updated_after_completion_initiated: 'Journal uppdaterad efter slutförd kontroll',
      waiting_to_start: 'Redo att starta'
    },
    timeline: {
      no_results_generic: 'Ingen historik.',
      no_results_errand: 'Ingen historik för ärenden.',
      no_results_appointment: 'Ingen historik för kontroller.',
      no_results_journal_signing: 'Övrig historik saknas.'
    },
    care_team: {
      responsible_doctor: 'PAL',
      responsible_nurse: 'PAS',
      temporary_doctor: 'Tillfällig PAL',
      temporary_nurse: 'Tillfällig PAS'
    }
  },
  my_patients: {
    upcoming: {
      ongoing: 'Förberedelser pågår',
      paused: 'Programmet pausat, inväntar patient'
    }
  },
  error_boundary: {
    header: 'Något gick fel :(',
    summary: 'Visa teknisk information'
  },
  server_error: {
    db_access_error: 'Databasfel',
    internal: 'Internt serverfel',
    unknown_user: 'Okänd användare',
    insufficient_privileges: 'Användaren saknar behörighet',
    authentication_error: 'Autentiseringsfel',
    user_canceled_login: 'Användaren avbröt inloggningen',
    login_already_in_process: 'Inloggning redan påbörjad',
    external_service_timeout: 'Extern tjänst tog för lång tid att svara',
    not_implemented: 'Inte implementerad',
    invalid_data_posted: 'Data skickades i felaktigt format',
    missing_data: 'Data saknas',
    id_already_exists: 'ID existerar redan',
    fallback: 'Okänt fel - kontakta teknisk support',
    connectivity: 'Kunde inte kontakta servern. Kontrollera internetanslutningen.'
  },
  network_error: {
    400: 'Felaktig begäran',
    401: 'Inte autentiserad',
    404: 'Resursen hittades inte',
    405: 'Metoden tillåts inte',
    409: 'Konflikt',
    500: 'Internt serverfel, kontakta teknisk support',
    502: 'Bad gateway',
    503: 'Tjänst inte tillgänglig'
  }
};
